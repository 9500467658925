var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Icon" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(" You can add icon with buttons. ")
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "HomeIcon" }
              }),
              _c("span", { staticClass: "align-middle" }, [_vm._v("Home")])
            ],
            1
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "warning" }
            },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "StarIcon" }
              }),
              _c("span", { staticClass: "align-middle" }, [_vm._v("Star")])
            ],
            1
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(40, 199, 111, 0.15)",
                  expression: "'rgba(40, 199, 111, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "flat-success" }
            },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "CheckIcon" }
              }),
              _c("span", { staticClass: "align-middle" }, [_vm._v("Done")])
            ],
            1
          ),
          _c(
            "b-button",
            { attrs: { variant: "gradient-danger" } },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "HeartIcon" }
              }),
              _c("span", { staticClass: "align-middle" }, [_vm._v("Favorite")])
            ],
            1
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary", disabled: "" }
            },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "HomeIcon" }
              }),
              _c("span", { staticClass: "align-middle" }, [_vm._v("Disabled")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }