var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Checkbox & radio group" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCheckboxRadio) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-checkbox-group", {
                    attrs: {
                      options: _vm.optionsChekcbox,
                      name: "buttons-1",
                      buttons: "",
                      "button-variant": "primary"
                    },
                    model: {
                      value: _vm.selectedCheckbox,
                      callback: function($$v) {
                        _vm.selectedCheckbox = $$v
                      },
                      expression: "selectedCheckbox"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "btn-radios-1",
                      "button-variant": "outline-primary",
                      options: _vm.optionsRadio,
                      buttons: "",
                      name: "radios-btn-default"
                    },
                    model: {
                      value: _vm.selectedRadio,
                      callback: function($$v) {
                        _vm.selectedRadio = $$v
                      },
                      expression: "selectedRadio"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }