var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-code",
        {
          attrs: { title: "Relief" },
          scopedSlots: _vm._u([
            {
              key: "code",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.codeButtonRelief) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-card-text", { staticClass: "mb-0" }, [
            _c("span", [_vm._v("Use ")]),
            _c("code", [_vm._v(".btn-relief-{color}")]),
            _c("span", [_vm._v(" to create a relief button.")])
          ]),
          _c(
            "div",
            { staticClass: "demo-inline-spacing" },
            [
              _c("b-button", { attrs: { variant: "relief-primary" } }, [
                _vm._v(" Primary ")
              ]),
              _c("b-button", { attrs: { variant: "relief-secondary" } }, [
                _vm._v(" Secondary ")
              ]),
              _c("b-button", { attrs: { variant: "relief-success" } }, [
                _vm._v(" Success ")
              ]),
              _c("b-button", { attrs: { variant: "relief-danger" } }, [
                _vm._v(" Danger ")
              ]),
              _c("b-button", { attrs: { variant: "relief-warning" } }, [
                _vm._v(" Warning ")
              ]),
              _c("b-button", { attrs: { variant: "relief-info" } }, [
                _vm._v(" Info ")
              ]),
              _c("b-button", { attrs: { variant: "relief-dark" } }, [
                _vm._v(" Dark ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }