var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Gradient" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeButtonGradient) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("use ")]),
        _c("code", [_vm._v('variant="gradient-{color}"')]),
        _c("span", [_vm._v(" prop create gradient buttons.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-button", { attrs: { variant: "gradient-primary" } }, [
            _vm._v(" Primary ")
          ]),
          _c("b-button", { attrs: { variant: "gradient-secondary" } }, [
            _vm._v(" Secondary ")
          ]),
          _c("b-button", { attrs: { variant: "gradient-success" } }, [
            _vm._v(" Success ")
          ]),
          _c("b-button", { attrs: { variant: "gradient-danger" } }, [
            _vm._v(" Danger ")
          ]),
          _c("b-button", { attrs: { variant: "gradient-warning" } }, [
            _vm._v(" Warning ")
          ]),
          _c("b-button", { attrs: { variant: "gradient-info" } }, [
            _vm._v(" Info ")
          ]),
          _c("b-button", { attrs: { variant: "gradient-dark" } }, [
            _vm._v(" Dark ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }